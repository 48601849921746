<template>
  <div class="body">
    <div class="container">
      <nav class="topbar" v-if="!getToken">
        <div class="brand">
          <router-link to="/" class="brand-icon">
            <img src="@/assets/Logo@2x.png" class="brand-logo" />
          </router-link>
        </div>
      </nav>
      <div id="texte_a_afficher" class="texte_inselectionnable">
        <p style="text-align: center; font-size: 22px">
          <strong>Conditions Générales d’Utilisation</strong>
        </p>
        <p>
          Les présentes conditions générales s’appliquent automatiquement à tous
          les utilisateurs de BATIGO, qu’ils soient abonnés ou non. Elles
          régissent les conditions d’accès, d’utilisation et de vente de BATIGO.
          L’utilisation du logiciel BATIGO et de tous les autres services
          connexes que la Société fournit (support technique depuis le site,
          blog, centre d’aide, etc.) est donc régie par les présentes conditions
          générales.
        </p>
        <p>
          En cochant la case d’acceptation des présentes conditions
          d’utilisation localisée sur la page d’inscription de BATIGO, vous
          acceptez de façon expresse, pleine et entière d’être lié par les
          présentes conditions d’utilisation et de respecter toute consigne,
          documentation, instruction ou nécessité logicielle indiquée dans ce
          document.
        </p>
        <p>
          La Société SAS BATIGO se réserve le droit de changer ou de mettre à
          jour ces termes et conditions périodiquement et à sa seule discrétion
          en les publiant sur son site ou en fournissant toute autre
          notification. À chaque changement majeur, la Société notifie le Client
          par tout moyen sous un délai raisonnable.
        </p>
        <p>
          Il appartient à l’utilisateur de prendre connaissance de la version la
          plus récente publiée sur le site internet de BATIGO
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>1. Définitions et interprétations</strong></span
          >
        </p>
        <dl>
          <p>Société : SAS BATIGO, éditeur du Site.</p>
          <p>
            Abonnement : mode de facturation appliqué par la Société en échange
            des prestations définies.
          </p>
          <p>
            Utilisateur : personne physique ou personne morale recourant aux
            Services proposés par BATIGO et/ou ayant ouvert un Compte sur le
            Site après avoir accepté les présentes conditions générales.
          </p>
          <p>
            Client : personne qui souscrit un Abonnement à BATIGO après avoir
            accepté les présentes conditions générales.
          </p>
          <p>Compte : espace propre à chaque Utilisateur de BATIGO.</p>
          <p>
            Contrat : ensemble des dispositions, termes et conditions découlant
            du présent document ainsi que de la Politique Vie Privée incorporée
            au contrat par référence.
          </p>
          <p>
            Données Client : toutes les données et informations implémentées par
            le Client dans BATIGO ou collectées et traitées au moyen de BATIGO
            pour le compte du Client. Sont compris les textes, images et tout
            contenu, de quelque nature qu’il soit, échangé lors de l’utilisation
            du support technique du Site et/ou du Compte.
          </p>
          <p>
            Prospect : client potentiel de BATIGO pour lequel des opérations
            commerciales et/ou de communication sont mises en place.
          </p>
          <p>
            Services : ensemble des services accessibles via BATIGO dans les
            conditions prévues aux présentes.
          </p>
          <p>
            Site : ensemble des sites (application, site vitrine, blog, centre
            d’aide) édités par la Société.
          </p>
        </dl>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>2. Description de BATIGO</strong></span
          >
        </p>
        <p>
          BATIGO est un logiciel hébergé à distance et disponible sur Internet,
          ainsi que le support technique associé. Le logiciel permet aux
          utilisateurs de créer facilement des devis et des factures dans leur
          langue de choix parmi les 50 langues proposées. Les documents peuvent
          ensuite être édités en langue française. Le support technique fourni
          se limite à une assistance pour l'utilisation des fonctionnalités du
          logiciel par les utilisateurs. La Société ne peut être tenue
          responsable des erreurs de saisie ou de la qualité des documents
          édités par les utilisateurs. BATIGO se réserve le droit d'apporter des
          modifications à ses fonctionnalités, à son design ou à toute autre
          partie de son logiciel à tout moment et sans préavis.
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>3. Responsabilité de la Société</strong></span
          >
        </p>
        <p>
          La Société s'engage à traiter les données à caractère personnel du
          Client conformément à la réglementation en vigueur. Elle ne traite les
          données à disposition que pour les seules finalités d'exécuter les
          obligations qui lui incombent en exécution du Contrat et de prévenir
          ou résoudre des problèmes techniques ou de service, pour se conformer
          aux obligations légales et réglementaires, ou après autorisation
          expresse par écrit du Client.
        </p>
        <p>
          Par exemple, nous vous présentons des solutions logicielles en
          cohérence avec votre profil de société ou de particulier. En matière
          de propositions logicielles, les abonnements affichés vont tenir
          compte de votre souhait ou de votre type de solutions technique.
          Batigo attache la plus grande importance à la protection des données
          personnelles de ses Utilisateurs et Clients, sur ses sites internet et
          applications mobiles .<br />La Société s'engage également à mettre en
          œuvre tous les efforts raisonnables afin de maintenir BATIGO à la
          disposition du Client 24 heures sur 24 et 7 jours sur 7, conformément
          aux règles de l'art communément admises. En cas de questions
          concernant l'utilisation du logiciel, La Société fournit un support
          technique accessible via les coordonnées et les heures d'ouverture
          indiquées sur le site Web de BATIGO.
        </p>
        <p>
          Toutefois, la Société se réserve le droit de rendre moins accessible
          ou indisponible, pendant une durée déterminée, l'interface de BATIGO
          et/ou tout ou partie de ses fonctionnalités pour corriger tout bug,
          installer des mises à jour ou faire tout diagnostic ou toute
          maintenance de BATIGO. La Société ne saurait être tenue responsable de
          toute suspension ou indisponibilité de BATIGO et/ou de l'accès à ses
          serveurs, en totalité ou en partie.
        </p>
        <p>
          Dans le cadre de sa mission de mise en œuvre des services de BATIGO
          pour le compte de ses Clients, la Société peut communiquer les données
          collectées en tant que de besoin à des fournisseurs de services tiers.
          Ces transferts de données sont réalisés dans la limite nécessaire à
          l'exécution des tâches qui leur sont confiées. Ces tiers sont
          pleinement informés de la confidentialité des données qui leur sont
          communiquées et ont l'obligation d'assurer la protection de ces
          données. La Société s'engage à prendre les mesures de sécurité
          techniques et organisationnelles nécessaires pour respecter les
          obligations imposées. Dans ce cadre et sauf stipulation contraire, la
          Société est responsable des actes commis par son personnel et ses
          fournisseurs de services tiers.
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>4. Responsabilité du Client</strong></span
          >
        </p>
        <p>
          Le Client est tenu responsable de l’exactitude, la qualité et la
          légalité des Données Client et des moyens par lesquels il les obtient.
          Le Client doit assurer la véracité des informations fournies et
          garantir leur conformité aux réglementations en vigueur. Il est
          également responsable du respect de la politique de confidentialité de
          la Société quant à l’utilisation des données collectées.
        </p>
        <p>
          Le Client s’engage à utiliser BATIGO de manière loyale et à des fins
          licites, en respectant le présent Contrat et les lois en vigueur. Il
          est strictement interdit au Client (a) de mettre BATIGO à disposition
          de tiers non autorisés dans le cadre du Contrat, (b) de distribuer
          BATIGO par une concession, un bail ou une sous-licence sans l’accord
          préalable écrit de la Société, (c) d’accéder ou d’utiliser BATIGO en
          contournant les mesures de protection logiques ou physiques, (d) de
          copier ou de reproduire tout ou partie de BATIGO, et (e) d’accéder à
          BATIGO en vue d’élaborer un produit ou service concurrent. Le
          non-respect de ces conditions peut entraîner la résiliation immédiate
          du Contrat par la Société, sans que cela ne puisse engager sa
          responsabilité.
        </p>
        <p>
          Le Client est responsable de la compatibilité de BATIGO avec ses
          appareils mobiles, ses navigateurs web, ses réseaux et ses connexions
          Internet, ainsi que de la configuration de ses systèmes informatiques,
          et de tous les frais qui y sont associés. L’accès à BATIGO dépend de
          la disponibilité du réseau de l’opérateur mobile ou du fournisseur
          d’accès Internet du Client, et la Société ne peut en aucun cas être
          tenue responsable de la disponibilité des services de ces tiers,
          notamment en cas de perte, de dommage, d’erreur ou d’échec de
          transmission de données.
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>5. Tarifs et conditions financières</strong></span
          >
        </p>
        <p>
          Le coût et les modalités d’abonnement à BATIGO sont clairement
          indiqués au moment du processus d’inscription et demeurent disponibles
          en ligne. Les montants sont exprimés en euros et sont soumis à la TVA.
        </p>
        <p>
          Afin de garantir la qualité et la sécurité du paiement en ligne, la
          Société utilise les services d'un prestataire de paiement : Stripe. La
          politique de confidentialité de ce prestataire est accessible ici.
          Lors d'un paiement en ligne, le prestataire de paiement collecte des
          données personnelles concernant vos moyens de paiement (numéro de
          carte bancaire, date de fin de validité de la carte bancaire, etc.).
          Il est important de souligner que la Société ne collecte ni ne détient
          aucune donnée bancaire directement.
        </p>
        <p>
          Pour permettre au client d'évaluer l'adéquation de BATIGO à ses
          besoins, la Société propose une période d'essai gratuite et limitée
          dans le temps de 15 jours, sauf stipulation contraire émanant de la
          Société. À l'issue de cette période d'essai, l'utilisateur a le choix
          de s'abonner ou non. En cas d'abonnement, le montant de la redevance
          sera calculé conformément au tarif en vigueur à la date de
          souscription, sauf accord contraire entre les parties.
        </p>
        <p>
          Le client doit fournir à la Société des informations de facturation et
          de paiement complètes et à jour afin de permettre la facturation et le
          paiement des sommes dues en exécution du Contrat. Sauf disposition
          contraire, les redevances sont payables terme à échoir (paiement
          chaque mois de la redevance du mois d'abonnement à venir). Il est de
          la responsabilité du client d'informer la Société de toute
          modification relative à ces informations de paiement.
        </p>
        <p>
          En cas de retard de paiement d'une facture, la Société informera le
          client par tout moyen dans un délai de 15 jours. Si le retard de
          paiement excède 30 jours, la Société se réserve le droit de (a)
          demander le paiement immédiat de toute somme qui pourrait être
          facturée, (b) facturer le client d'un montant de 40 euros par facture
          pour frais de recouvrement et (c) suspendre tout accès à BATIGO
          jusqu'au parfait paiement de ces montants. Ces droits ne seront pas
          exercés si les montants en question font l'objet d'une contestation
          sérieuse et de bonne foi de la part du client, et si ce dernier
          collabore activement pour résoudre le différend l'opposant à la
          Société.
        </p>
        <p>
          Enfin, la Société se réserve le droit de modifier les tarifs de ses
          services à tout moment. Le client est informé que son choix s'est
          porté sur la version de BATIGO au jour de sa commande et que ce choix
          n'était en aucune manière déterminé par la délivrance ou l'ajout d'une
          fonctionnalité ou d'une caractéristique future.
        </p>
        <p>
          Sauf indication contraire, toutes les notifications et les accords en
          lien avec l'exécution du Contrat sont transmis par courrier
          électronique et sont considérés comme communiqués le premier jour
          ouvrable suivant leur envoi. Les notifications relatives aux questions
          financières seront envoyées à l'adresse e-mail fournie par le Client à
          cet effet. Le Client est responsable de s'assurer que l'adresse e-mail
          fournie est à jour et accessible. La Société ne sera pas tenue
          responsable si le Client ne reçoit pas une notification en raison
          d'une adresse e-mail invalide ou désuète.
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>6. Droits exclusifs et licences</strong></span
          >
        </p>
        <p>
          Le présent Contrat ne prévoit aucune cession de propriété ni de droits
          de propriété intellectuelle de quelque nature que ce soit. La Société
          conserve tous les droits et titres de propriété relatifs au contenu de
          BATIGO. Le Client ne se voit accorder aucun droit autre que ceux
          expressément mentionnés dans le présent Contrat.
        </p>
        <p>
          La Société accorde au Client un droit d'utilisation de BATIGO pour le
          monde entier et pendant la durée du Contrat. Le Client accorde à la
          Société un droit d'hébergement, de reproduction et de représentation
          des Données Client pour le monde entier et pour la durée du Contrat,
          augmentée du temps des prescriptions légales en vigueur. Ce droit est
          accordé uniquement dans le but d'exécuter les obligations des parties
          au titre du présent Contrat. La Société ne peut acquérir aucun droit
          de propriété sur les Données Client en vertu du présent Contrat.
        </p>
        <p>
          Le Client accorde à la Société le droit d'utiliser et d'incorporer
          dans BATIGO toute suggestion, demande d'amélioration, recommandation,
          correction ou commentaire faits par le Client ou les utilisateurs. Ce
          droit est irrévocable et s'entend sans aucune contrepartie financière,
          ce que le Client accepte expressément. Le Client ne peut céder ses
          droits ou obligations en vertu du présent Contrat sans l'accord écrit
          préalable de la Société, qui ne peut refuser cet accord que pour des
          motifs légitimes. Aucun tiers ne peut prétendre au bénéfice du présent
          Contrat ou des obligations qui y sont stipulées.
        </p>
        <p>
          Le présent Contrat constitue l'intégralité de l'accord entre les
          parties concernant son objet et annule et remplace tous les contrats,
          négociations et discussions antérieurs entre les parties à cet égard.
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong
              >7. Confidentialité et protection des données personnelles</strong
            ></span
          >
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>7.1 Confidentialité</strong></span
          >
        </p>
        <p>
          Les parties s’engagent à maintenir confidentielles toutes les
          informations et données qu’elles pourraient détenir ou recevoir dans
          le cadre de l’exécution du Contrat et qui sont expressément désignées
          comme étant confidentielles ou dont le caractère confidentiel peut
          être raisonnablement présumé (ci-après dénommées « Informations
          Confidentielles »).
        </p>
        <p>
          Les Informations Confidentielles ne peuvent être communiquées à aucun
          tiers, ni être utilisées par les parties à des fins autres que celles
          prévues par le Contrat, sans l’accord préalable écrit de la partie
          émettrice des Informations Confidentielles. Les parties conviennent de
          prendre toutes les mesures nécessaires pour protéger la
          confidentialité des Informations Confidentielles, notamment en
          s’assurant que ces dernières ne soient pas divulguées, copiées ou
          reproduites sans autorisation écrite.
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>7.2 Protection des données personnelles</strong></span
          >
        </p>
        <p>
          Les parties s’engagent à respecter les dispositions légales et
          réglementaires applicables en matière de protection des données à
          caractère personnel et à mettre en place toutes les mesures
          nécessaires pour assurer la sécurité et la confidentialité des données
          personnelles traitées dans le cadre du Contrat.
        </p>
        <p>
          Le Client est responsable de l’ensemble des traitements de données
          personnelles effectués dans le cadre de l’utilisation de BATIGO. La
          Société agit en qualité de sous-traitant au sens de la législation
          applicable en matière de protection des données à caractère personnel.
        </p>
        <p>
          Le Client garantit que toutes les personnes concernées par les données
          personnelles ont été informées des traitements mis en œuvre et ont
          donné leur consentement, le cas échéant. Le Client s’engage à coopérer
          pleinement avec la Société pour lui permettre de remplir ses
          obligations légales en matière de protection des données à caractère
          personnel.
        </p>
        <p>
          La Société s’engage à mettre en œuvre toutes les mesures techniques et
          organisationnelles nécessaires pour garantir la sécurité et la
          confidentialité des données personnelles traitées dans le cadre du
          Contrat. La Société s’engage également à n’utiliser les données
          personnelles traitées que pour les seules finalités prévues par le
          Contrat.
        </p>
        <p>
          En cas de violation de données à caractère personnel, la Société
          s’engage à en informer le Client dans les meilleurs délais après en
          avoir pris connaissance et à coopérer avec lui pour permettre la
          notification à l’autorité de contrôle compétente et, le cas échéant,
          aux personnes concernées.
        </p>
        <p>
          Les parties conviennent de conclure un accord de traitement des
          données personnelles conforme à la réglementation applicable.
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong
              >8. Garanties, limitation et exclusion de responsabilité</strong
            ></span
          >
        </p>
        <p>La Société s'engage à respecter les garanties suivantes :</p>
        <p>
          (a) Elle ne fera pas évoluer à la baisse la sécurité globale de BATIGO
          pendant la durée du contrat.
        </p>
        <p>
          (b) BATIGO fonctionnera conformément à sa description telle qu'elle
          figure dans les documents contractuels.
        </p>
        <p>
          (c) Elle ne modifiera pas de manière substantielle les fonctionnalités
          existantes de BATIGO.
        </p>
        <p>
          (d) BATIGO n'introduira pas de code malveillant dans les systèmes du
          client.
        </p>
        <p>
          La Société garantit également le client contre toute réclamation,
          demande, poursuite judiciaire ou procédure faite ou engagée contre lui
          par un tiers alléguant que l'utilisation de BATIGO en conformité avec
          le contrat viole ses droits de propriété intellectuelle. Dans ce cas,
          la Société indemnisera le client de tous les dommages et intérêts,
          honoraires et frais de justice qui en résulteraient en cas de
          condamnation définitive ou de tout montant payé en vertu d'un
          règlement amiable validé par un tribunal. Toutefois, pour bénéficier
          de cette garantie, le client doit :
        </p>
        <p>
          (a) Notifier immédiatement la Société par écrit de cette réclamation.
        </p>
        <p>
          (b) Donner à la Société le contrôle exclusif du litige et/ou du
          règlement amiable de cette réclamation. Il est entendu que la Société
          ne peut accepter un règlement amiable que s'il dégage sans condition
          le client de toute responsabilité.
        </p>
        <p>
          (c) Fournir toute l'assistance requise à la Société, aux frais de la
          Société si besoin était.
        </p>
        <p>
          De son côté, le client garantit la Société contre toute réclamation,
          demande, poursuite judiciaire ou procédure faite ou engagée contre
          elle par un tiers alléguant que les données du client, ou
          l'utilisation de BATIGO par le client en violation du contrat, viole
          l'un quelconque de ses droits ou le droit applicable. Cette garantie
          couvre la Société contre tout dommage, honoraire et frais de justice
          qui en résulterait ou de tout montant que la Société paierait en vertu
          d'un règlement amiable validé par un tribunal. Pour bénéficier de
          cette garantie, la Société doit :
        </p>
        <p>
          (a) Notifier par écrit le client de l'existence de cette réclamation
          dans les meilleurs délais après en avoir eu connaissance.
        </p>
        <p>
          (b) Donner au client le contrôle exclusif de la contestation et du
          règlement amiable de cette réclamation. Il est entendu qu'un tel
          règlement amiable devra dégager la Société de toute responsabilité
          sans terme ni condition.
        </p>
        <p>
          (c) Apporter au client toute l'assistance raisonnable qui pourra être
          sollicitée par le client, le cas échéant aux frais du client.
        </p>
        <p>
          Les deux paragraphes précédents décrivent l'intégralité de la
          responsabilité qui pourrait être encourue par une partie vis-à-vis de
          l'autre pour les types de réclamations qui y sont décrites.
        </p>
        <p>
          Si la Société est informée d'une réclamation en contrefaçon se
          rapportant à BATIGO, elle peut, à sa discrétion et sans frais pour le
          client :
        </p>
        <p>
          (i) Modifier BATIGO de manière à mettre fin à la situation de
          contrefaçon,
        </p>
        <p>
          (ii) Obtenir un droit d'utilisation autorisant le client à continuer à
          utiliser BATIGO conformément au Contrat,
        </p>
        <p>
          (iii) résilier le Contrat ou supprimer la partie contrefaisante,
          moyennant un préavis écrit de 30 jours calendaires et le remboursement
          de tous les montants payés d'avance au titre de la période
          contractuelle en cours.
        </p>
        <p>
          En cas de litige lié au Contrat ou à son exécution, la responsabilité
          de chaque partie sera limitée pour chaque litige au montant payé à la
          Société au titre du Contrat au cours des 12 mois précédant le fait
          générateur du litige. La responsabilité cumulée des litiges liés au
          Contrat ou à son exécution sera limitée au montant total payé à la
          Société au titre du Contrat. Toutefois, ces limitations ne
          s'appliqueront pas en cas d'action en contrefaçon ou de concurrence
          déloyale de la part de la Société.
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>9. Durée de validité et résiliation</strong></span
          >
        </p>
        <p>
          Le Contrat entre en vigueur à la date d'inscription de l'Utilisateur
          et est reconduit tacitement pour des durées successives égales à la
          période initiale.
        </p>
        <p>Le Client peut résilier le Contrat sans préavis.</p>
        <p>
          Dans un délai de trois ans suivant la date d'effet de la résiliation
          ou dans un délai de 30 jours si le Client en fait explicitement la
          demande au moment de la résiliation, la Société mettra les Données
          Client à disposition du Client pour leur exportation ou leur
          téléchargement. Passé ce délai, la Société ne sera pas tenue de
          conserver ou de fournir les Données Client et pourra, à sa discrétion,
          détruire ou anonymiser toutes les copies des Données Client stockées
          sur ses systèmes.
        </p>
        <p>
          Toute clause du Contrat qui, de manière explicite ou implicite, a
          vocation à produire des effets après la résiliation ou l'expiration du
          Contrat, restera en vigueur après ladite résiliation ou expiration. La
          résiliation ou l'expiration du Contrat n'affectera pas les droits,
          recours, obligations ou engagements dont les parties peuvent se
          prévaloir ou auxquels elles sont tenues à la date de la résiliation ou
          de l'expiration.
        </p>

        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong
              >10. Droit applicable et compétence juridictionnelle</strong
            ></span
          >
        </p>
        <p>
          Les présentes Conditions Générales sont régies, interprétées et
          appliquées conformément au droit français. En cas de litige relatif à
          l’interprétation ou à l’exécution du Contrat, les parties
          s’efforceront de parvenir à un règlement amiable. À défaut, toute
          difficulté sera portée devant le Tribunal de Commerce de Paris, qui
          aura une compétence exclusive, quelle que soit la localisation de
          l’exécution du contrat, le lieu de domicile du défendeur ou le mode de
          règlement, y compris dans le cas d’un appel en garantie, d’une
          pluralité de défendeurs ou d’une procédure en référé. Si une
          stipulation du Contrat est jugée inopposable par un tribunal, cette
          décision n’affectera pas la validité des autres stipulations, qui
          continueront de produire leurs effets. Les Parties déclarent et
          reconnaissent qu’elles sont et demeureront, pendant toute la durée du
          Contrat, indépendantes, et que le Contrat ne peut conférer à l’une ou
          l’autre la qualité de mandataire ou de représentant de son
          cocontractant. En outre, le fait pour l’une des Parties de ne pas
          exercer un droit qu’elle détient en vertu du Contrat ne saurait être
          interprété comme une renonciation à ce droit, qui pourra être exercé
          ultérieurement.
        </p>
      </div>
    </div>
    <!-- <footer>
      <div class="link">
        <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
        <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
        <router-link to="/cgu">{{ $t("C.G.U") }}</router-link>
      </div>
      <p>{{ $t("COPYRIGHT") }}</p>
    </footer> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lang: 0,
    };
  },

  methods: {
    ...mapActions(["changeLang"]),

    chooseLang(lang) {
      this.changeLang(lang);
    },

    close(e) {
      if (this.$refs["langue"])
        if (!this.$refs["langue"].contains(e.target)) {
          this.lang = 0;
        }
    },
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getToken",
      "getSelectedLang",
      "getAllLangue",
      "getActiveLangue",
    ]),
  },

  mounted() {
    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: block;
  width: 100%;
}
.container {
  padding: 15px;
  margin-top: 55px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
  }
}

a {
  color: #e4261b !important;
  text-decoration: underline !important;
}

footer {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  border-top: 13px solid #e4261b;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #28367a;
  height: 92px;
  color: #fff;
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 35%;
    display: flex;
    justify-content: space-between;

    & a {
      text-decoration: underline;
      color: #fff !important;

      &:hover {
        color: #ffffff80;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
